import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { FdrLink } from '@hotelplan/supergraph-api';
import type { TFdrBreadcrumb } from 'components/candidate/fdr-breadcrumbs';
import { TQueryCtx } from 'config/types/page.types';
import {
  FdrHomePageUrlDocument,
  FdrHomePageUrlQuery,
  FdrHomePageUrlQueryVariables,
} from 'schemas/query/home/fdr-home-page-url.generated';
import {
  FdrThemeGeoBreadcrumbsDocument,
  FdrThemeGeoBreadcrumbsQuery,
  FdrThemeGeoBreadcrumbsQueryVariables,
} from 'schemas/query/theme-geo/fdr-theme-geo-breadcrumbs.generated';
import {
  FdrThemeBreadcrumbsDocument,
  FdrThemeBreadcrumbsQuery,
  FdrThemeBreadcrumbsQueryVariables,
} from 'schemas/query/theme/fdr-theme-breadcrumbs.generated';
import {
  createBreadcrumb,
  getHardcodedCaption,
  getHomeBreadcrumb,
} from './utils.common';

function getCommonThemeBreadcrumbs(
  data: {
    fdrHomePage?: { webMeta: { link: FdrLink } };
    fdrThemeOverviewPage?: { webMeta: { link: FdrLink } };
  },
  lang: string
): Array<TFdrBreadcrumb> {
  return [
    ...getHomeBreadcrumb(data, lang),
    createBreadcrumb(
      data?.fdrThemeOverviewPage?.webMeta.link || null,
      getHardcodedCaption(EPageType.ThemeOverview, lang)
    ),
  ];
}

export async function getFdrThemeOverviewBreadcrumbs(
  queryCtx: TQueryCtx,
  lang: string
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrHomePageUrlQuery,
    FdrHomePageUrlQueryVariables
  >({
    query: FdrHomePageUrlDocument,
  });

  const breadcrumbs = getCommonThemeBreadcrumbs(data, lang);
  breadcrumbs[1].disabled = true;

  return breadcrumbs;
}

export async function getFdrThemeBreadcrumbs(
  queryCtx: TQueryCtx,
  lang: string,
  pageId: string
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrThemeBreadcrumbsQuery,
    FdrThemeBreadcrumbsQueryVariables
  >({
    query: FdrThemeBreadcrumbsDocument,
    variables: { id: pageId },
  });

  if (!data?.fdrTheme) {
    return [];
  }

  const commonBreadcrumbs = getCommonThemeBreadcrumbs(data, lang);

  return [
    ...commonBreadcrumbs, // home, theme overview
    createBreadcrumb(null, data?.fdrTheme.name, true), // current theme
  ];
}

export async function getFdrThemeGeoBreadcrumbs(
  queryCtx: TQueryCtx,
  lang: string,
  { geoId, themeId }: { themeId: string; geoId: string }
): Promise<Array<TFdrBreadcrumb>> {
  const { data } = await queryCtx<
    FdrThemeGeoBreadcrumbsQuery,
    FdrThemeGeoBreadcrumbsQueryVariables
  >({
    query: FdrThemeGeoBreadcrumbsDocument,
    variables: { geoId, themeId },
  });

  if (!data?.fdrThemeGeoPage?.page) {
    return [];
  }

  const commonBreadcrumbs = getCommonThemeBreadcrumbs(data, lang);

  return [
    ...commonBreadcrumbs, // home, theme overview
    // theme
    createBreadcrumb(
      data?.fdrThemeGeoPage.page.theme.webMeta.descriptionWebMeta?.link,
      data?.fdrThemeGeoPage.page.theme.name
    ),
    createBreadcrumb(null, data?.fdrThemeGeoPage.page.breadcrumbTitle, true), // current theme geo
  ];
}
