import styled from 'styled-components';
import { MOBILE_SCREEN_MEDIA_QUERY } from './fdr-image-text-component.constants';

export const FdrImageTextComponentWrapper = styled.div<{
  imagePlacement: string;
}>(({ theme: { space, text }, imagePlacement }) => ({
  display: 'flex',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '818px',
  justifyContent: 'center',
  flexDirection: imagePlacement !== 'RIGHT' ? `row` : `row-reverse`,
  alignItems: 'start',
  gap: '20px',
  paddingLeft: space[4],
  paddingRight: space[4],
  paddingBottom: '50px',
  '.image': {
    flex: 1,
    flexShrink: 0,
    img: {
      maxHeight: '100%',
      maxWidth: '100%',
      width: '100%',
    },
  },
  '.text': {
    ...text.richText,
    flex: 1,
    flexShrink: 0,
  },
  '.title': {
    fontSize: '30px',
    paddingTop: space[3],
    marginBottom: space[3],
  },
  [MOBILE_SCREEN_MEDIA_QUERY]: {
    marginLeft: space[4],
    marginRight: space[4],
    alignItems: 'initial',
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
