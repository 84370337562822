import { useTranslation } from 'next-i18next';
import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrProductRouteMapModal } from './fdr-product-route-map-modal';
import { TFdrRouteMapProduct } from './fdr-product-route-map.types';
import { useFdrMapAccessVisibilityFeatureToggle } from './use-fdr-map-access-visibility-feature-toggle';

const ProductRouteMapButtonWrap = styled.div(
  sx2CssThemeFn({
    position: 'relative',
    marginLeft: 'auto',
  })
);

const ProductRouteMapButton = styled(BsButtonWithIcon)(
  ({ theme: { colors, media, fontSizes } }) => ({
    background: colors.lightGreyThird,
    height: '42px',
    width: '42px',
    borderRadius: '5px',
    svg: {
      color: 'black',
      fill: 'transparent',
    },
    '~ .tooltip': {
      display: 'none',
      position: 'absolute',
      top: '53px',
      width: 'max-content',
    },
    '&:hover,&:focus,&:active': {
      border: `1px solid ${colors.redHover}`,
      background: colors.lightGreyThird,
      svg: {
        color: colors.redHover,
        fill: 'transparent',
      },
    },
    '&:hover': {
      '~ .tooltip': {
        display: 'block',
        color: 'white',
        fontWeight: '400',
        fontSize: fontSizes[1],
        lineHeight: '22px',
        letterSpacing: '0.5px',
        backgroundColor: colors.darkGrey,
        boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '5px',
        padding: '5px 10px',
        right: '0',
        zIndex: '110',
        '&:before': {
          content: '""',
          position: 'absolute',
          width: '16px',
          height: '16px',
          right: '13px',
          top: '-8px',
          backgroundColor: colors.darkGrey,
          transform: 'rotate(-45deg)',
          zIndex: '1',
        },
        [media.mobile]: {
          display: 'none',
        },
      },
    },
    '&:disabled': {
      opacity: 0.5,
      background: colors.lightGreyThird,
      cursor: 'auto',
      '&:hover,&:focus,&:active': {
        border: `1px solid ${colors.black}`,
        svg: {
          color: 'black',
          fill: 'transparent',
        },
      },
    },
  })
);

const ProductRouteMapTextButton = styled(BsButtonWithIcon)(
  ({ theme: { colors, space } }) => ({
    position: 'relative',
    gap: '10px',
    '.icon': {
      fill: 'transparent',
      width: '22px',
      height: '22px',
    },
    '&::after': {
      position: 'absolute',
      bottom: '-6px',
      right: 0,
      display: 'block',
      height: '2px',
      content: '""',
      width: 'calc(100% - 32px)',
      backgroundColor: colors.redWeb,
      opacity: 0.2,
      transition: '0.2s ease-in',
    },
    '&::before': {
      position: 'absolute',
      bottom: '-6px',
      left: space[6],
      display: 'block',
      height: '2px',
      content: '""',
      width: '2px',
      backgroundColor: colors.redWeb,
    },

    '&:hover': {
      color: colors.redHover,
      '&::after': {
        opacity: 1,
        backgroundColor: colors.redHover,
      },
    },

    '&:active, &:focus': {
      color: colors.redActive,
      '&::after': {
        opacity: 1,
        backgroundColor: colors.redActive,
      },
    },
    '&:hover:active, &:focus:active, &:active:active': {
      '.icon': {
        fill: 'transparent',
      },
    },
  })
);

interface IFdrProductMapProps {
  id: string;
  products: Array<TFdrRouteMapProduct>;
  onMapOpenClick?(): void;
  modalTitle?: string;
}

export function FdrProductRouteMap(
  props: IFdrProductMapProps
): React.ReactElement {
  const { id, products, onMapOpenClick, modalTitle } = props;

  const [t] = useTranslation('search');
  const { mobile } = useDeviceType();
  const [isMapOpened, openMap, closeMap] = useToggleState(false);

  const handleMapOpenClick = () => {
    openMap();
    onMapOpenClick?.();
  };

  const isMapAccessVisibilityFeatureToggleEnabled =
    useFdrMapAccessVisibilityFeatureToggle();

  useEffect(() => {
    if (!window) return;

    window.document.body.style.overflow = isMapOpened ? 'hidden' : 'initial';
  }, [isMapOpened]);

  const renderMap = useCallback(() => {
    if (!products?.length) return null;

    return (
      <FdrProductRouteMapModal
        products={products}
        isMapOpened={isMapOpened}
        onClose={closeMap}
        id={id}
        title={modalTitle}
      />
    );
  }, [closeMap, isMapOpened, products, id, mobile]);

  return (
    <ProductRouteMapButtonWrap>
      {isMapAccessVisibilityFeatureToggleEnabled ? (
        <ProductRouteMapTextButton
          variant="linkBtn"
          icon={{
            name: 'map-icon',
            size: 'md',
          }}
          className="product-routing-map-button"
          disabled={!products?.length}
          onClick={handleMapOpenClick}
        >
          <span className="link-text">
            {t('product.route.map.button.tooltip')}
          </span>
        </ProductRouteMapTextButton>
      ) : (
        <>
          <ProductRouteMapButton
            variant="iconBtn"
            icon={{
              name: 'map-icon',
              size: 'md',
            }}
            className="product-routing-map-button"
            disabled={!products?.length}
            onClick={handleMapOpenClick}
          />
          <span className="tooltip">
            {t('product.route.map.button.tooltip')}
          </span>
        </>
      )}
      {renderMap()}
    </ProductRouteMapButtonWrap>
  );
}
