import React, { ReactElement } from 'react';
import FdrBenefitsSection from 'components/domain/fdr-benefits-section/fdr-benefits-section';
import FdrContactSection from 'components/domain/fdr-contacts-section/fdr-contact-section';
import { FdrMediaGalleryPageComponent } from 'components/domain/fdr-page-components/media-gallery/fdr-media-gallery-page-component';
import { FdrMarketingRecommender } from 'components/domain/fdr-page-components/recommenders/marketing/fdr-marketing-recommender';
import { FdrUspBoxes } from 'components/domain/fdr-usp-boxes';
import { FdrPageComponentsFragment } from 'schemas/fragment/page-components/fdr-page-components.generated';
import { FdrFaqContainer, FdrFaqWrapper } from './fdr-page-components.styles';
import FdrImageTextComponent from './image-text/fdr-image-text-component';
import FdrHeroMediaGalleryPageComponent from './media-gallery/fdr-hero-media-gallery-page-component';
import FdrAgencyPageComponent from './recommenders/agency/fdr-agency-page-component';
import FdrBlogPageComponent from './recommenders/blog-article/fdr-blog-page-component';
import FdrGeoPageComponent from './recommenders/geo/fdr-geo-page-component';
import FdrProductPageComponent from './recommenders/product/fdr-product-page-component';
import FdrThemeGeoPageComponent from './recommenders/theme-geo/fdr-theme-geo-page-component';
import FdrThemePageComponent from './recommenders/theme/fdr-theme-page-component';
import FdrTextPageComponent from './text-component/fdr-text-page-component';

interface IFdrPageComponentsProps {
  components?: FdrPageComponentsFragment['components'];
  bookmarked?: boolean;
}

export function FdrPageComponents({
  components,
  bookmarked,
}: IFdrPageComponentsProps): ReactElement {
  return (
    <>
      {components?.map(function renderPageComponent(component, i) {
        switch (component.__typename) {
          case 'FdrImageText': {
            return <FdrImageTextComponent key={i} data={component} />;
          }

          case 'FdrMarkdownText':
          case 'FdrRawText': {
            return (
              <FdrTextPageComponent key={i} component={component} index={i} />
            );
          }

          case 'FdrFaq': {
            const isPrevIsFaq = components[i - 1]?.__typename === 'FdrFaq';
            const isNextIsFaq = components[i + 1]?.__typename === 'FdrFaq';

            return (
              <FdrFaqContainer key={i}>
                <FdrFaqWrapper
                  mt={!isPrevIsFaq}
                  mb={!isNextIsFaq}
                  {...component}
                />
              </FdrFaqContainer>
            );
          }

          case 'FdrHeroMediaGallery': {
            return (
              <FdrHeroMediaGalleryPageComponent
                key={i}
                component={component}
                bookmarked={bookmarked}
              />
            );
          }

          case 'FdrMediaGallery': {
            return (
              <FdrMediaGalleryPageComponent component={component} key={i} />
            );
          }

          case 'FdrUspBoxesComponent': {
            return component?.boxes ? (
              <FdrUspBoxes
                key={i}
                boxes={component.boxes}
                backgroundColor={component.bgColor}
                color={component.fgColor}
              />
            ) : null;
          }

          case 'FdrBenefitsComponent': {
            return (
              <FdrBenefitsSection
                key={`benefits-${component.id}`}
                component={component}
              />
            );
          }

          case 'FdrBlogArticleRecommender': {
            return (
              <FdrBlogPageComponent
                key={`blog-article-section-${component.id}`}
                component={component}
              />
            );
          }

          case 'FdrContactModule': {
            return (
              <FdrContactSection
                key={`contacts-section-${component.id}`}
                component={component}
              />
            );
          }

          case 'FdrMarketingRecommender': {
            return (
              <FdrMarketingRecommender
                key={`recommendation-group-${i}`}
                component={component}
              />
            );
          }

          case 'FdrAgencyRecommender': {
            return (
              <FdrAgencyPageComponent
                key={`agency-recommendation-section-${component.id}`}
                component={component}
              />
            );
          }

          case 'FdrGeoRecommender': {
            if (!component.itemsPage.items?.length) return null;

            return (
              <FdrGeoPageComponent
                key={`geo-rec-section-${component.id}`}
                component={component}
              />
            );
          }

          case 'FdrThemeRecommender': {
            if (!component.themePage.themes?.length) return null;

            return (
              <FdrThemePageComponent
                key={`static-theme-rec-group-${i}`}
                component={component}
              />
            );
          }

          case 'FdrThemeGeoRecommender':
            if (!component.itemsPage.items?.length) return null;

            return (
              <FdrThemeGeoPageComponent
                key={`static-theme-rec-group-${i}`}
                component={component}
              />
            );

          case 'FdrProductRecommender': {
            if (!component.productPage.products?.length) return null;

            return (
              <FdrProductPageComponent
                key={`static-product-rec-group-${component.id}`}
                component={component}
              />
            );
          }

          default: {
            return null;
          }
        }
      })}
    </>
  );
}
