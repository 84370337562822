import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useLazyFetchingPagination } from '@hotelplan/libs.hooks';
import { useViewportSize } from '@hotelplan/libs.hooks-dom';
import { CardsSection } from 'components/domain/customizable-slider/CardsSection';
import {
  CARDS_PER_PAGE_THEME,
  CARDS_PER_PAGE_THEME_SMALLDESKTOP,
} from 'components/domain/customizable-slider/CustomizableSlider.constants';
import {
  IFetchComponentResponse,
  TCommonRecommenderItem,
} from 'components/domain/fdr-page-components/fdr-page-components.types';
import { FdrThemeRecommendationSlider } from 'components/domain/fdr-page-components/recommenders/theme/fdr-theme-recommendation-slider';
import { FdrImageTeaser } from 'components/domain/fdr-teaser-card/fdr-image-teaser';
import {
  ListContainer,
  RecommendationsGrid,
} from 'components/domain/recommendations/Recommendations.common';

type TFdrThemeRecommenderProps = {
  total: number;
  items: TCommonRecommenderItem[];
  displayType?: string;
  id?: string;
  title?: string;
  bgColor?: string;
  fontColor?: string;
  fetchItems?(
    page: number,
    perPage: number
  ): Promise<IFetchComponentResponse<TCommonRecommenderItem>>;
} & HTMLAttributes<HTMLDivElement>;

const ThemeListContainer = styled(ListContainer)<{
  isSlides: boolean;
  titleColor?: string;
}>(({ isSlides, theme: { colors } }) => ({
  '@media screen and (max-width: 1023px)': {
    paddingRight: isSlides ? 0 : '16px',
  },
  '.pages': {
    color: colors.white,
  },
}));

const ThemeListWithSlidesNavigationContainer = styled(ListContainer)<{
  isSlides: boolean;
  titleColor?: string;
}>(({ isSlides, titleColor, theme: { colors } }) => ({
  '@media screen and (max-width: 1023px)': {
    paddingRight: isSlides && 0,
  },
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '20px',
  paddingRight: '40px',
  paddingLeft: '40px',
  '.count': {
    color: titleColor || colors.white,
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '20px',
  },
}));

export function FdrThemeRecommender({
  total,
  displayType = 'SWIPE',
  title,
  items: initialItems,
  bgColor,
  fontColor,
  fetchItems,
  ...divProps
}: TFdrThemeRecommenderProps): React.ReactElement {
  const { width } = useViewportSize();
  const { mobile } = useDeviceType();
  const isSlides = displayType === 'SWIPE';
  const isSliderPagination = !mobile;

  const ThemeListContainerVariant = isSliderPagination
    ? ThemeListWithSlidesNavigationContainer
    : ThemeListContainer;

  const smallerDesktop = width <= 1249 && width >= 1024;
  const perPage = smallerDesktop
    ? CARDS_PER_PAGE_THEME_SMALLDESKTOP
    : CARDS_PER_PAGE_THEME;

  const { items, page, prevPage, nextPage, setPage } =
    useLazyFetchingPagination(initialItems, {
      total,
      perPage,
      fetchItems,
    });

  const itemsResult = items.map((item, index) => (
    <FdrImageTeaser
      cfg={{
        title: item.title,
        link: item.link,
        lazy: isSlides && index > 2,
      }}
      key={index}
      image={item.image}
      data-id="theme-card"
    />
  ));

  return (
    <div
      className="themes-section"
      data-id="theme-recommendations-section"
      {...divProps}
      style={{
        ...(divProps.style || {}),
        color: fontColor,
        backgroundColor: bgColor,
      }}
    >
      <ThemeListContainerVariant
        data-display={displayType}
        isSlides={isSlides}
        titleColor={fontColor}
      >
        {!isSlides ? (
          <CardsSection title={title} page={1} totalPages={1}>
            <RecommendationsGrid style={{ borderColor: fontColor }}>
              {itemsResult}
            </RecommendationsGrid>
          </CardsSection>
        ) : (
          <FdrThemeRecommendationSlider
            total={total}
            page={page}
            perPage={perPage}
            prevPage={prevPage}
            nextPage={nextPage}
            setPage={setPage}
            title={title}
            titleColor={fontColor}
          >
            {itemsResult}
          </FdrThemeRecommendationSlider>
        )}
      </ThemeListContainerVariant>
    </div>
  );
}
