import { track } from '@hotelplan/libs.tracking-events';

export const trackSRLSearchFieldClick = (): void => {
  track({
    event: `🚀 click.openSearch`,
    _clear: true,
  });
};

export const trackProductRouteMapIconClick = (language: string): void => {
  track({
    event: `open_map`,
    action: `open`,
    label: `map`,
    category: undefined,
    component: 'map-icon',
    position: undefined,
    language,
    _clear: true,
  });
};

export const trackSearch = (
  label: string,
  resultsCount: number,
  language: string
) => {
  track({
    event: `search_results`,
    action: `view`,
    label: label,
    category: undefined,
    component: undefined,
    position: undefined,
    language,
    search_results_counts: resultsCount,
    _clear: true,
  });
};
