import {
  TFdrAdventureTravelProduct,
  TFdrHotelProduct,
  TFdrRoundTripProduct,
  TFdrThProduct,
  TTypedProduct,
} from './fdr-product.types';

export const isFdrAdventureTravelProduct = <T extends TTypedProduct>(
  object: T
): object is TFdrAdventureTravelProduct<T> => {
  return object?.__typename === 'FdrAdventureTravel';
};

export const isFdrHotelProduct = <T extends TTypedProduct>(
  object: T
): object is TFdrHotelProduct<T> => {
  return object?.__typename === 'FdrHotel';
};

export const isFdrRoundTripProduct = <T extends TTypedProduct>(
  object: T
): object is TFdrRoundTripProduct<T> => {
  return object?.__typename === 'FdrRoundTrip';
};

export const isFdrThProduct = <T extends TTypedProduct>(
  object: T
): object is TFdrThProduct<T> => {
  return (
    isFdrAdventureTravelProduct(object) ||
    isFdrHotelProduct(object) ||
    isFdrRoundTripProduct(object)
  );
};

export const getProductBreadcrumbsFromResort = (resort: {
  name: string;
  destination: {
    name: string;
    country: { name: string };
    region?: { name: string };
  };
}): string[] => {
  return Array.from(
    new Set([
      resort.name,
      resort.destination.name,
      resort.destination.region?.name,
      resort.destination.country.name,
    ])
  );
};
