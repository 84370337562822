import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { PaginationArrowClickSource } from 'components/domain/tracking/paginationTracking';
import { FdrSearchItem } from './fdr-search-item';
import { FdrSearchRouteMap } from './fdr-search-route-map';
import {
  FdrSearchSectionSkeleton,
  geoSearchCardPlaceholder,
  searchCardPlaceholder,
} from './fdr-search-section.skeleton';
import {
  CARDS_GAP,
  DEFAULT_IMAGE_HEIGHT,
  DEFAULTS_RESULT_PER_PAGE,
  FDR_SEARCH_SECTION_KEYS,
  MIN_ALL_RESULTS_PAGE_ITEMS_COUNT,
} from './fdr-search.constants';
import {
  FdrAllItemsButton,
  FdrCardsSlider,
  FdrSearchCardsSection,
} from './fdr-search.styles';
import {
  mapSearchItemToNormalizedSearchItem,
  mapSectionKeyToTitle,
} from './fdr-search.utils';
import { useSearchResultContext } from './SearchResultContext.context';
import { useFdrTextSearch } from './useFdrTextSearch';

interface IFdrSearchSectionProps {
  sectionKey: typeof FDR_SEARCH_SECTION_KEYS[number];
  searchInput: string;
  setIsContentExist: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export function FdrSearchSection({
  sectionKey,
  searchInput,
  setIsContentExist,
  setIsLoading,
}: IFdrSearchSectionProps): ReactElement {
  const [t] = useTranslation('search');
  const { mobile } = useDeviceType();
  const { channelType } = useAuthentication();
  const { query, pathname, push } = useRouter();

  const [imageHeight, setImageHeight] = useState(DEFAULT_IMAGE_HEIGHT);

  const searchResultContext = useSearchResultContext();

  function handleImageHeight(height: number) {
    if (!height) return;
    setImageHeight(height);
  }

  const { itemList, currentPage, pagination, loading, fetchItems } =
    useFdrTextSearch(searchInput, sectionKey);

  const { total } = pagination || {};

  function onNextPage() {
    fetchItems(currentPage + 1);
  }

  function onPrevPage() {
    fetchItems(currentPage - 1);
  }

  function onChangePage(nextPage: number): void {
    fetchItems(nextPage);
  }

  const placeholder =
    sectionKey === 'geo' ? geoSearchCardPlaceholder : searchCardPlaceholder;

  const showAllResultBtn = !mobile && total >= MIN_ALL_RESULTS_PAGE_ITEMS_COUNT;
  const isB2B = channelType === AuthChannelType.B2B;
  const sectionTitle = t(mapSectionKeyToTitle(sectionKey));
  const itemsLoaded = itemList && itemList.length;

  const prevSearchInput = useRef<string>(searchInput);
  const isSearchSectionTrackingNeeded = useRef<boolean>(true);

  if (prevSearchInput.current !== searchInput) {
    isSearchSectionTrackingNeeded.current = true;
    prevSearchInput.current = searchInput;
  }

  useEffect(() => {
    if (isSearchSectionTrackingNeeded && pagination?.page === 0) {
      isSearchSectionTrackingNeeded.current = false;

      searchResultContext.setSectionData(sectionKey, {
        total: pagination.total,
      });
    }
  }, [pagination]);

  useEffect(() => {
    setIsLoading(loading);
  }, [setIsLoading, loading]);

  useEffect(() => {
    setIsContentExist(Boolean(total));
  }, [total, setIsContentExist]);

  if (loading && !itemsLoaded) return <FdrSearchSectionSkeleton />;

  if (!itemsLoaded || total === 0) return null;

  return (
    <FdrSearchCardsSection
      isSRL
      title={sectionTitle}
      totalCount={total}
      page={currentPage}
      totalPages={Math.ceil(total / DEFAULTS_RESULT_PER_PAGE)}
      onNextPage={onNextPage}
      onPrevPage={onPrevPage}
      paginationLoading={loading}
      isSliderPagination={!mobile}
      imageHeight={imageHeight}
      trackingSource={PaginationArrowClickSource.SRL}
      routingMap={
        sectionKey === 'adventuretravel' && !loading ? (
          <FdrSearchRouteMap searchInput={searchInput} isLoading={loading} />
        ) : null
      }
      headerStyle={{ height: '42px' }}
      underSectionNode={
        showAllResultBtn ? (
          <FdrAllItemsButton
            onClick={() => {
              push({
                pathname: pathname,
                query: { s: query['s'], section: sectionKey },
              });
            }}
          >
            {t('show.all.items', {
              section: sectionTitle,
              keywords: searchInput,
            })}
          </FdrAllItemsButton>
        ) : null
      }
    >
      <FdrCardsSlider
        hideDelimiter
        page={currentPage}
        total={total || itemList.length}
        perPage={DEFAULTS_RESULT_PER_PAGE}
        onChangePage={onChangePage}
        placeholder={placeholder}
        cardsGap={CARDS_GAP}
      >
        {itemList.map((item, index) =>
          item ? (
            <FdrSearchItem
              key={index}
              lazy={index + 1 > DEFAULTS_RESULT_PER_PAGE}
              searchItem={mapSearchItemToNormalizedSearchItem(item, isB2B)}
              sectionKey={sectionKey}
              getImageHeight={
                index === currentPage * DEFAULTS_RESULT_PER_PAGE
                  ? handleImageHeight
                  : undefined
              }
              className={`srl-${sectionKey}-${index + 1}`}
            />
          ) : null
        )}
      </FdrCardsSlider>
    </FdrSearchCardsSection>
  );
}
