import {
  FdrBookmarkObjectType,
  FdrDisrupter,
  FdrImage,
} from '@hotelplan/supergraph-api';
import { isFdrThGeoObject } from 'components/domain/fdr-geo/fdr-geo.utils';
import {
  isFdrAdventureTravelProduct,
  isFdrHotelProduct,
  isFdrRoundTripProduct,
  isFdrThProduct,
} from 'components/domain/fdr-product/fdr-product.utils';
import {
  TBookmarkItem,
  TFdrBookmarkedGeo,
  TFdrBookmarkedProduct,
  TFdrBookmarkedTheme,
  TTypedBookmarkEntryObject,
} from './fdr-bookmark-types';

export const isFdrGeoBookmark = <T extends TTypedBookmarkEntryObject>(
  object: T
): object is TFdrBookmarkedGeo<T> => {
  return object?.__typename === 'FdrGeoBookmark';
};

export const isFdrProductBookmark = <T extends TTypedBookmarkEntryObject>(
  object: T
): object is TFdrBookmarkedProduct<T> => {
  return object?.__typename === 'FdrProductBookmark';
};

export const isFdrThemeBookmark = <T extends TTypedBookmarkEntryObject>(
  object: T
): object is TFdrBookmarkedTheme<T> => {
  return object?.__typename === 'FdrThemeBookmark';
};

export function mapObjectToBookmarkType<T extends TTypedBookmarkEntryObject>(
  object: T
): FdrBookmarkObjectType {
  if (isFdrProductBookmark(object)) return FdrBookmarkObjectType.Product;
  if (isFdrThemeBookmark(object)) return FdrBookmarkObjectType.Theme;
  if (isFdrGeoBookmark(object)) return FdrBookmarkObjectType.Geo;

  return;
}

export const mapObjectIdToBookmarkObjectType = (
  id: string
): FdrBookmarkObjectType => {
  const type = id?.split('-')[0];

  switch (type) {
    default:
      return FdrBookmarkObjectType.Product;
    case 'k':
    case 'c':
    case 'r':
      return FdrBookmarkObjectType.Geo;
    case 't':
      return FdrBookmarkObjectType.Theme;
  }
};

export const getBookmarkedObjectItemFromFdrBookmark = (
  object: TBookmarkItem
) => {
  if (isFdrProductBookmark(object) && isFdrThProduct(object.product)) {
    return object.product;
  }

  if (isFdrGeoBookmark(object) && isFdrThGeoObject(object.geo)) {
    return object.geo;
  }

  if (isFdrThemeBookmark(object)) {
    return object.theme;
  }
};

export const getBookmarkImageFromFdrBookmark = (
  object: TBookmarkItem
): FdrImage => {
  let image: FdrImage;

  if (
    isFdrProductBookmark(object) &&
    isFdrAdventureTravelProduct(object.product)
  ) {
    image = object.product.headerImages?.[0];
  }

  if (
    isFdrProductBookmark(object) &&
    (isFdrHotelProduct(object.product) || isFdrRoundTripProduct(object.product))
  ) {
    return object.product.hero;
  }

  if (isFdrGeoBookmark(object)) {
    image = object.geo.hero;
  }

  if (isFdrThemeBookmark(object)) {
    image = object.theme.hero;
  }

  return image;
};

export function getDisrupters(bookmarkObject: {
  __typename?: string;
  product?: {
    __typename?: string;
    disrupters?: Array<FdrDisrupter>;
  };
}): Array<FdrDisrupter> {
  if (bookmarkObject?.__typename === 'FdrProductBookmark') {
    if (bookmarkObject?.product?.__typename === 'FdrAdventureTravel') {
      return bookmarkObject.product.disrupters;
    }
  }
}

export enum BookmarkBlockActionType {
  RESET,
  SET_VIEW,
}

export enum BookmarkBlockView {
  DEFAULT,
  NEW_BOOKMARK_GROUP,
  ADD_TO_BOOKMARK_GROUP,
  NEW_BOOKMARK_GROUP_BACK,
}

type BookmarkBlockActionPayload = {
  view: BookmarkBlockView;
};

type TBookmarkBlockAction = {
  type: BookmarkBlockActionType;
  payload?: BookmarkBlockActionPayload;
};

type TBookmarkBlockState = {
  view: BookmarkBlockView;
};

export const bookmarkBlockReducer = (
  state: TBookmarkBlockState,
  action: TBookmarkBlockAction
): TBookmarkBlockState => {
  switch (action.type) {
    case BookmarkBlockActionType.SET_VIEW:
      return {
        ...state,
        view: action.payload.view,
      };
    default:
      return {
        ...state,
        view: BookmarkBlockView.DEFAULT,
      };
  }
};

export const bookmarkBlockInitialState: TBookmarkBlockState = {
  view: BookmarkBlockView.DEFAULT,
};
