import React from 'react';
import { FdrImage } from '@hotelplan/fdr.regular.basis.fdr-image';
import { FdrImageTextFragment } from 'schemas/fragment/regular/fdrText.generated';
import { FdrImageTextComponentWrapper } from './fdr-image-text-component.styles';

interface IFdrImageTextComponentProps {
  data: FdrImageTextFragment;
}

const FdrImageTextComponent = ({ data }: IFdrImageTextComponentProps) => {
  const { text, image, placement, title } = data;
  return (
    <FdrImageTextComponentWrapper
      imagePlacement={placement}
      data-id="image-text-component"
    >
      {image ? (
        <FdrImage
          image={{ ...image, resized: image.imageTextResized }}
          className="image"
        />
      ) : null}
      <div>
        <h2 className="title">{title}</h2>
        <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </FdrImageTextComponentWrapper>
  );
};

export default FdrImageTextComponent;
