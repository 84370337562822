import React, { useCallback } from 'react';
import { useLazyFetchingPagination } from '@hotelplan/libs.hooks';
import {
  createPageComponentVariables,
  mapFdrPageComponentsResponseToAgencies,
} from 'components/domain/fdr-page-components/fdr-page-components.utils';
import useFdrPageComponentsFetch from 'components/domain/fdr-page-components/useFdrPageComponentsFetch';
import { FdrPageComponentsFragment } from 'schemas/fragment/page-components/fdr-page-components.generated';
import { FdrAgencyItemFragment } from 'schemas/fragment/recommenders/agency/fdr-agency-recommender.generated';
import { FdrAgencyComponentsDocument } from 'schemas/query/agencies/fdr-paginated-agency-recommender.generated';
import { FdrAgencyRecommender } from './fdr-agency-recommender';
import { FdrFlexibleAgencyItem } from './fdr-flexible-agency-item';

const AGENCIES_PER_PAGE = 3;

interface IFdrAgencyPageComponentProps {
  component: Extract<
    FdrPageComponentsFragment['components'][number],
    { __typename: 'FdrAgencyRecommender' }
  >;
}

const FdrAgencyPageComponent: React.FC<IFdrAgencyPageComponentProps> = ({
  component,
}) => {
  const perPage = AGENCIES_PER_PAGE;

  const mapVariables = useCallback(
    (componentPage, componentPerPage) => {
      return createPageComponentVariables(
        component?.id,
        componentPage,
        componentPerPage
      );
    },
    [component?.id]
  );

  const { fetchItems, loading } = useFdrPageComponentsFetch(
    FdrAgencyComponentsDocument,
    mapVariables,
    mapFdrPageComponentsResponseToAgencies
  );

  const fdrAgencies = component.agencies.agenciesPage || [];

  const { items, page, prevPage, nextPage, setPage } =
    useLazyFetchingPagination<FdrAgencyItemFragment>(fdrAgencies, {
      total: component.agencies.pagination.total || 0,
      perPage,
      fetchItems,
    });

  return (
    <FdrAgencyRecommender
      total={component.agencies.pagination.total}
      page={page}
      prevPage={prevPage}
      nextPage={nextPage}
      setPage={setPage}
      fgColor={component.fgColor}
      bgColor={component.bgColor}
      title={component.title}
      loading={loading}
    >
      {items.map((agency, i) => {
        return (
          <FdrFlexibleAgencyItem
            key={`agency-recommendation-item-${i}`}
            agency={agency}
          />
        );
      })}
    </FdrAgencyRecommender>
  );
};

export default FdrAgencyPageComponent;
