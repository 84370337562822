import { useTranslation } from 'next-i18next';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import { trackSearch } from 'components/domain/tracking/srlTracking';
import { B2B_SECTION_KEYS, B2C_SECTION_KEYS } from './fdr-search.constants';

type TSectionKey =
  | typeof B2B_SECTION_KEYS[number]
  | typeof B2C_SECTION_KEYS[number];

type TResultsItem = {
  total: number | null;
};

type TSearchResultContext = {
  results: Record<TSectionKey, TResultsItem>;
  setSectionData: (sectionId: TSectionKey, data: TResultsItem) => void;
};

export const SearchResultContext = React.createContext<TSearchResultContext>({
  results: undefined,
  setSectionData: () => void 0,
});

export const SearchResultProviderWrapper: React.FC<
  React.PropsWithChildren<{ searchInput: string }>
> = ({ children, searchInput }) => {
  const { channelType } = useAuthentication();
  const [, { language }] = useTranslation();

  const isB2B = channelType === AuthChannelType.B2B;
  const sectionKeys = isB2B ? B2B_SECTION_KEYS : B2C_SECTION_KEYS;

  const initialResultsValue = useMemo(() => {
    return Object.fromEntries(
      sectionKeys.map((key: TSectionKey) => [
        key,
        {
          total: null,
        },
      ])
    );
  }, [sectionKeys]);

  const [results, setResults] =
    useState<Record<TSectionKey, TResultsItem>>(initialResultsValue);

  const prevSearchInputValue = useRef<string>(searchInput);

  if (prevSearchInputValue.current !== searchInput) {
    prevSearchInputValue.current = searchInput;

    setResults(initialResultsValue);
  }

  const setSectionData = useCallback(
    (sectionId: string, data: TResultsItem) => {
      setResults(prevState => {
        if (prevState[sectionId]?.total !== data.total) {
          return {
            ...prevState,
            [sectionId]: data,
          };
        }

        return prevState;
      });
    },
    []
  );

  useEffect(() => {
    const entries = Object.entries(results);

    if (entries.length && entries.every(([, value]) => value.total !== null)) {
      const resultsSum = Object.values(results).reduce(
        (acc, num) => acc + num.total,
        0
      );

      trackSearch(searchInput, resultsSum, language);
    }
  }, [results, searchInput, language]);

  return (
    <SearchResultContext.Provider
      value={{
        results,
        setSectionData,
      }}
    >
      {children}
    </SearchResultContext.Provider>
  );
};

export function useSearchResultContext(): TSearchResultContext {
  return React.useContext(SearchResultContext);
}
